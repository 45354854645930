<template>
  <div class="rs-admin-edit">
    <v-banner
      app
      sticky
      elevation="8"
      color="white"
      style="z-index: 6"
      class="mx-n8 mt-n4"
    >
      <v-row>
        <v-col>
          <v-btn text to="/admin/advisors"
            ><v-icon class="mr-4">mdi-arrow-left</v-icon> Advisors</v-btn
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end">
          <v-btn to="/admin/advisors" text color="error" class="mr-4">
            Cancel
          </v-btn>

          <v-btn :disabled="!valid" @click="handleSave" color="primary">
            {{ mode === "new" ? "Add Advisor" : "Save" }}
          </v-btn>
        </v-col>
      </v-row></v-banner
    >

    <v-row class="mt-4">
      <v-col class="col-12 col-md-6">
        <v-card-title>
          <span
            >{{ mode === "edit" ? "Edit Existing" : "Add New" }} Advisor</span
          >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="panels.length === panelsCount"
            text
            color="primary"
            @click="handleTogglePanels('open')"
            >Open All</v-btn
          >
          <v-btn
            :disabled="panels.length <= 0"
            text
            color="primary"
            @click="handleTogglePanels('close')"
            >Close All</v-btn
          >
        </v-card-title>

        <v-form ref="form" v-model="valid">
          <v-expansion-panels hover multiple v-model="panels">
            <!-- Company Panel -->
            <v-expansion-panel>
              <v-expansion-panel-header
                >Company Details</v-expansion-panel-header
              >
              <v-expansion-panel-content eager>
                <v-text-field
                  v-model="advisor.companyName"
                  label="Company Name"
                  outlined
                  clearable
                  type="text"
                  class="mb-2 pt-6"
                ></v-text-field>
                <v-text-field
                  v-model="advisor.companyUrl"
                  :rules="[handleCheckUrl]"
                  label="Company Website"
                  hint="Enter a valid URL"
                  persistent-hint
                  outlined
                  clearable
                  type="text"
                  class="mb-4"
                ></v-text-field>
                <v-row>
                  <v-col>
                    <!-- COMPANY LOGO START CONDITION -->
                    <v-text-field
                      v-if="advisor.companyLogo"
                      :value="advisor.companyLogo.key"
                      :disabled="true"
                      label="Company Logo"
                      class="mb-4"
                    ></v-text-field>
                    <v-file-input
                      v-else
                      v-model="advisor.companyLogo"
                      :rules="imageRules"
                      accept="image/png, image/jpeg, image/bmp, image/svg"
                      prepend-icon="mdi-camera"
                      outlined
                      label="Company Logo"
                    ></v-file-input>
                    <!-- COMPANY LOGO END CONDITION -->
                  </v-col>
                  <v-col>
                    <p>
                      Select image to upload. Max size is 5MB. Edit photos
                      before upload with max dimensions of 600px width (this
                      will likely change and an image editor will be added to
                      ui).
                    </p>
                  </v-col>
                </v-row>
                <!-- Company Address -->
                <v-card-subtitle class="px-0">
                  <span>Company Address</span>
                </v-card-subtitle>
                <template v-if="advisor.companyAddress">
                  <v-alert text type="warning">
                    <p>
                      This address text area blob has been deprecated. Please
                      copy the parts of the address to the appropriate fields
                      below and save.
                    </p>
                    <v-textarea
                      outlined
                      v-model="advisor.companyAddress"
                      class="white"
                      hide-details
                      light
                      >{{ advisor.companyAddress }}</v-textarea
                    >
                  </v-alert>
                </template>

                <v-text-field
                  v-model="advisor.address.streetAddress1"
                  label="Street Address 1"
                  outlined
                  clearable
                  type="text"
                ></v-text-field>
                <v-text-field
                  v-model="advisor.address.streetAddress2"
                  label="Street Address 2"
                  outlined
                  clearable
                  type="text"
                ></v-text-field>

                <v-text-field
                  v-model="advisor.address.city"
                  label="City"
                  outlined
                  clearable
                  type="text"
                ></v-text-field>

                <v-row>
                  <v-col>
                    <v-select
                      v-model="advisor.address.state"
                      :items="states"
                      item-text="name"
                      item-value="abbreviation"
                      :menu-props="{ maxHeight: '400' }"
                      label="State"
                      clearable
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="advisor.address.postalCode"
                      label="Postal Code"
                      outlined
                      clearable
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Advisor Panel -->
            <v-expansion-panel>
              <v-expansion-panel-header
                >Advisor Details
                <small class="ml-2 error--text font-weight-bold"
                  >Required Fields</small
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content eager>
                <v-text-field
                  v-model="advisor.email"
                  :rules="emailRules"
                  label="Advisor Email"
                  hint="REQUIRED"
                  persistent-hint
                  outlined
                  clearable
                  required
                  type="email"
                  class="mb-4 pt-6 rs-text-field--required"
                ></v-text-field>
                <v-checkbox
                  v-model="useNotificationEmails"
                  label="Use alternative emails for notifications?"
                  class="mb-4"
                ></v-checkbox>
                <v-combobox
                  v-if="useNotificationEmails"
                  v-model="notificationEmails"
                  :rules="[handleEmailRules]"
                  chips
                  label="Add Notification Emails"
                  multiple
                  outlined
                  hint="Add email addresses one at a time. Tab or enter to commit email address. Email addresses added here will receive notification instead of your primary email address."
                  persistent-hint
                  append-icon=""
                  class="mb-4"
                >
                  <template v-slot:selection="{ attrs, item }">
                    <v-chip
                      v-bind="attrs"
                      close
                      :color="item.color"
                      @click:close="handleRemoveEmail(item)"
                    >
                      <strong>{{ item.text }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>

                <v-text-field
                  v-model="advisor.firstName"
                  :rules="firstNameRules"
                  label="Advisor First Name"
                  hint="REQUIRED"
                  persistent-hint
                  outlined
                  clearable
                  required
                  type="text"
                  class="mb-4 rs-text-field--required"
                ></v-text-field>
                <v-text-field
                  v-model="advisor.lastName"
                  :rules="lastNameRules"
                  label="Advisor Last Name"
                  hint="REQUIRED"
                  persistent-hint
                  outlined
                  clearable
                  required
                  type="text"
                  class="mb-4 rs-text-field--required"
                ></v-text-field>
                <v-text-field
                  v-model="advisor.phone"
                  v-maska="'###-###-####'"
                  label="Advisor Phone"
                  hint="Must be a real number with format: 555-555-5555"
                  persistent-hint
                  outlined
                  clearable
                  type="tel"
                  class="mb-4"
                ></v-text-field>
                <v-text-field
                  v-model="advisor.credentials"
                  label="Advisor Credentials"
                  outlined
                  clearable
                  type="text"
                  placeholder="MBA"
                ></v-text-field>

                <v-row>
                  <v-col>
                    <!-- AVATAR START CONDITION -->
                    <v-text-field
                      v-if="advisor.avatar"
                      :value="advisor.avatar.key"
                      :disabled="true"
                      label="Advisor Avatar"
                      class="mb-4"
                    ></v-text-field>
                    <v-file-input
                      v-else
                      :rules="imageRules"
                      v-model="advisor.avatar"
                      show-size
                      prepend-icon="mdi-camera"
                      outlined
                      label="Advisor Avatar"
                      class="mb-4"
                    ></v-file-input>
                    <!-- AVATAR END CONDITION -->
                  </v-col>
                  <v-col>
                    <p>
                      Select image to upload. Max size is 5MB. Edit photos
                      before upload with max dimensions of 600px width (this
                      will likely change and an image editor will be added to
                      ui).
                    </p>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Criteria Panel -->
            <v-expansion-panel>
              <v-expansion-panel-header
                >Prospect Qualification Criteria</v-expansion-panel-header
              >
              <v-expansion-panel-content eager>
                <v-card-subtitle class="pt-6 px-0">
                  <span>Criteria Set 1</span>
                </v-card-subtitle>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="advisor.prospectCriteria[0].birthYearRange[0]"
                      :items="yearBornOptions"
                      label="Birth Year Start"
                      dense
                      outlined
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="advisor.prospectCriteria[0].birthYearRange[1]"
                      :items="yearBornOptions"
                      label="Birth Year End"
                      dense
                      outlined
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="advisor.prospectCriteria[0].retirementSavings"
                      :items="retirementSavingsOptions"
                      label="Minimum Retirement Savings"
                      dense
                      outlined
                      hide-details
                      class="mb-2"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-card-subtitle class="px-0">
                  <span>Criteria Set 2</span>
                </v-card-subtitle>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="advisor.prospectCriteria[1].birthYearRange[0]"
                      :items="yearBornOptions"
                      label="Birth Year Start"
                      dense
                      outlined
                      hide-details
                      class="mb-2"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="advisor.prospectCriteria[1].birthYearRange[1]"
                      :items="yearBornOptions"
                      label="Birth Year End"
                      dense
                      outlined
                      hide-details
                      class="mb-2"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="advisor.prospectCriteria[1].retirementSavings"
                      :items="retirementSavingsOptions"
                      label="Minimum Retirement Savings"
                      dense
                      outlined
                      hide-details
                      class="mb-2"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Vanity Panel -->
            <v-expansion-panel>
              <v-expansion-panel-header
                >Advisor Vanity URL Names
                <small
                  v-if="mode === 'new'"
                  class="ml-2 error--text font-weight-bold"
                  >Required Fields</small
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content eager>
                <v-card-subtitle class="pa-0 pt-6 mb-2">
                  <span
                    >Vanity names are used in advisor's shareable score links
                    for prospects (e.g., https://riskscore.us/score/jdoe).</span
                  >
                </v-card-subtitle>
                <v-card-subtitle class="pa-0 mb-6">
                  Four advisor vanity names are permitted per advisor. There are
                  currently

                  <strong>{{ advisor.vanityNames.items.length }}</strong>
                  of <strong>4</strong> vanity names used for this advisor.
                </v-card-subtitle>
                <div>
                  <v-text-field
                    v-model="newVanityName"
                    v-if="advisor.vanityNames.items.length < 4"
                    :rules="[vanityNameRules]"
                    :required="mode === 'new'"
                    :hint="mode === 'new' ? 'REQUIRED' : ''"
                    :persistent-hint="mode === 'new' ? true : false"
                    label="Add Advisor URL Vanity Name"
                    outlined
                    clearable
                    type="text"
                    :color="vanityNameStatus"
                    :class="mode === 'new' ? 'rs-text-field--required' : ''"
                    @input="handleCheckVanityName($event)"
                  >
                    <template v-slot:append>
                      <!-- <v-fade-transition leave-absolute> -->
                      <v-progress-circular
                        v-if="checkingVanityName"
                        size="24"
                        color="info"
                        indeterminate
                      ></v-progress-circular>
                      <!-- <img
                                v-else
                                width="24"
                                height="24"
                                src="https://cdn.vuetifyjs.com/images/logos/v-alt.svg"
                                alt=""
                              /> -->
                      <!-- <v-icon v-else> mdi-text-search </v-icon> -->
                      <!-- </v-fade-transition> -->
                    </template>
                    <!-- <template v-slot:append-outer>
                              <v-btn>Check</v-btn>
                            </template> -->
                  </v-text-field>
                  <v-alert
                    v-if="
                      (newVanityName &&
                        vanityNameFieldValid &&
                        vanityNameStatus === 'success') ||
                      vanityNameStatus === 'error'
                    "
                    :type="vanityNameStatus"
                    dense
                    prominent
                    outlined
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <strong>{{ newVanityName }}</strong> is
                        <span v-if="vanityNameStatus === 'success'">
                          available.
                        </span>
                        <span v-else> <strong>not available</strong>.</span>
                      </v-col>
                      <v-col
                        v-if="vanityNameStatus === 'success'"
                        class="shrink"
                      >
                        <v-btn
                          @click="handleAddNewVanityName()"
                          :color="vanityNameStatus"
                          >Add Vanity Name</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                  <v-alert
                    v-if="advisor.vanityNames.items.length >= 4"
                    type="warning"
                    >Advisor is assigned the maximum number of vanity
                    names.</v-alert
                  >

                  <template v-if="advisor.vanityNames.items.length > 0">
                    Existing Vanity Names:
                    <v-chip
                      v-for="vn in advisor.vanityNames.items"
                      :key="vn.id"
                      class="mr-1"
                      >{{ vn.vanityName }}</v-chip
                    >
                  </template>
                  <template v-if="vanityNamesToAdd">
                    <v-chip
                      v-for="vn in vanityNamesToAdd"
                      :key="vn.id"
                      @click:close="handleRemoveVanityName(vn.id)"
                      close
                      color="success"
                      class="mr-1"
                      >{{ vn }}</v-chip
                    >
                  </template>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Disclosure Panel -->
            <v-expansion-panel>
              <v-expansion-panel-header
                >Advisor Disclosure</v-expansion-panel-header
              >
              <v-expansion-panel-content eager>
                <v-card-subtitle class="mb-3 px-0 pt-6"
                  >Enter a disclosure specific to this advisor. This disclosure
                  will appear in areas such as the advisor's prospect
                  reports.</v-card-subtitle
                >
                <div class="rs-tiptap">
                  <AdvisorTipTapEditor v-model="advisor.disclosure" />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Settings Panel -->
            <v-expansion-panel>
              <v-expansion-panel-header>Settings</v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <div>
                  <v-card-subtitle class="px-0 pt-6"
                    >Advisor Info Visibility
                  </v-card-subtitle>
                  <v-checkbox
                    v-model="advisor.showAdvisorCard"
                    label="Display Advisor Information on Landing Page?"
                    hint="Toggle whether to show the advisor name, company, etc. on the post-survey landing page."
                    persistent-hint
                    class="mt-0"
                  ></v-checkbox>
                </div>
                <div>
                  <v-card-subtitle class="px-0 pt-6"
                    >By-pass CRM
                  </v-card-subtitle>
                  <v-checkbox
                    v-model="advisor.bypassCrm"
                    label="Do not push prospects to CRM"
                    hint="Enabling will only send a transactional email to the advisor and bypass saving prospect to the CRM. Prospect won't receive an email."
                    persistent-hint
                    class="mt-0"
                  ></v-checkbox>
                </div>
                <div>
                  <v-card-subtitle class="px-0 pt-6"
                    >Custom Report CTA URL
                  </v-card-subtitle>
                  <v-text-field
                    v-model="advisor.customReportCtaUrl"
                    :rules="urlRules"
                    hint="Enter a valid URL. The prospect will be directed to the
      provided URL when they click the CTA on their report,
      instead of the RiskScore landing page. NOTE: This will override the Post-Survey Prospect Data Collection flow."
                    persistent-hint
                    label="Custom Report URL"
                    outlined
                    clearable
                    type="text"
                    placeholder="Custom URL"
                  ></v-text-field>
                </div>
                <div>
                  <v-card-subtitle class="px-0 pt-6"
                    >Advisor Video Embed URL
                  </v-card-subtitle>
                  <v-text-field
                    v-model="advisor.videoUrl"
                    :rules="youtubeUrlRules"
                    label="Video Embed URL"
                    hint="Video is embedded in Risk Score reports to sent prospects, if a video is included in the report template."
                    persistent-hint
                    outlined
                    clearable
                    type="text"
                  ></v-text-field>
                </div>
                <div>
                  <v-card-subtitle class="px-0 pt-6"
                    >Prospect Score Report Template
                  </v-card-subtitle>
                  <v-select
                    v-model="advisor.scoreReportTemplateId"
                    :items="scoreReportTemplates"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ maxHeight: '400' }"
                    label="Score Report Template"
                    clearable
                    outlined
                    hint="Score Report that advisor's prospect will see at /score-report/<pid>."
                    persistent-hint
                  ></v-select>
                </div>
                <div>
                  <v-card-subtitle class="px-0 pt-6"
                    >Post-Survey Prospect Data Collection
                  </v-card-subtitle>
                  <v-checkbox
                    v-model="advisor.collectContactPostSurvey"
                    label="Collect prospect data on landing page?"
                    class="mt-0"
                    hint="Enabling will remove contact info steps in the Risk Score Survey.
                    If a 'Custom Report CTA URL' is set above, the prospect will be directed to the provided URL when they click the CTA on their report, otherwise they will be directed to the RiskScore landing page with a form to have an advisor contact them at /reduce-risk/<prospect_id>."
                    persistent-hint
                  ></v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-col>
      <v-col class="col-12 col-md-6">
        <div class="px-3">
          <v-card-title>
            <span>Advisor Card Preview</span>
          </v-card-title>
          <v-card elevation="12" outlined>
            <AdvisorInfoCard :advisor="advisor" />
          </v-card>
          <v-divider class="my-8"></v-divider>
          <div>
            <v-card
              v-if="
                advisor &&
                advisor.prospects &&
                advisor.prospects.items.length > 0
              "
              outlined
              class="mx-auto"
            >
              <v-card-title class="grey lighten-5">
                Advisor's Prospects
                <v-chip class="ml-3">
                  {{ advisor.prospects.items.length }}
                </v-chip>
                <v-btn
                  color="primary"
                  class="ml-auto"
                  small
                  :to="`/admin/import/?advisor=${advisor.id}`"
                  >Import Prospects</v-btn
                >
              </v-card-title>

              <v-card-text class="pt-4">
                <strong>{{
                  prospectSurveyCompleteCount(advisor.prospects.items)
                }}</strong>
                of
                <strong>{{ advisor.prospects.items.length }}</strong> prospects
                have completed the survey.
              </v-card-text>

              <v-divider></v-divider>

              <v-virtual-scroll
                :items="advisor.prospects.items"
                :item-height="45"
                height="250"
              >
                <template v-slot:default="{ item }">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        ><small>{{ item.id }}</small></v-list-item-title
                      >
                    </v-list-item-content>

                    <v-list-item-action v-if="item.scoreSurveyComplete">
                      <v-btn
                        depressed
                        small
                        @click="handleCopyReportLink(item.id)"
                      >
                        Copy
                        <v-icon color="primary darken-4" right>
                          mdi-content-copy
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>

                    <v-list-item-action v-else> -- </v-list-item-action>

                    <!-- <v-list-item-action>
                      <v-btn
                        depressed
                        small
                        :to="handleOpenReportLink(item.id)"
                        target="_blank"
                      >
                        View
                        <v-icon color="primary darken-4" right>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>
                    </v-list-item-action> -->
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-card>
            <div v-else class="pa-3 text-center">Advisor has no prospects.</div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-overlay :value="showOverlay" color="#091437" z-index="10000">
      <v-progress-circular indeterminate size="70"></v-progress-circular>
    </v-overlay>

    <v-dialog v-if="dialog" persistent v-model="dialog" max-width="800px">
      <v-card tile>
        <v-card-title class="mb-6 text-h6 white--text" :class="dialogStatus">{{
          dialogTitle
        }}</v-card-title>
        <v-card-text v-html="dialogText"></v-card-text>
        <v-divider></v-divider>
        <v-list v-if="showDialogActions" flat>
          <v-subheader>What's next?</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="action in dialogActions"
              :key="action.id"
              @click="handleDialogAction(action.type, action.action)"
            >
              <v-list-item-icon>
                <v-icon v-text="action.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="action.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-card-actions v-if="showDialogButtons" class="justify-end">
          <v-btn text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showSnackBar"
      :timeout="2000"
      :color="snackBarColor"
      :elevation="24"
      transition="slide-x-reverse-transition"
      bottom
      right
      tile
    >
      <div class="text-center">{{ snackBarText }}</div>
    </v-snackbar>
  </div>
</template>

<script>
import { API, graphqlOperation, Storage } from "aws-amplify";
import awsExports from "@/aws-exports";
import { listReportTemplates, vanityInfoByVanityName } from "@/graphql/queries";
import { updateAdvisor, createAdvisorVanityName } from "@/graphql/mutations";
import { customGetAdvisor } from "@/customGraphQL/customGetAdvisor";
import { customCreateAdvisor } from "@/customGraphQL/customCreateAdvisor";
import vanityNameBlacklist from "@/assets/blacklist.json";
import states from "@/assets/states.json";
import parseCriteriaData from "@/mixins/parseAdvisorCriteria";
import linkBuilderMixin from "@/mixins/linkBuilderMixin";
import AdvisorInfoCard from "@/components/AdvisorInfoCard";
import AdvisorTipTapEditor from "@/components/editors/AdvisorTipTapEditor";

import {
  createCognitoUser,
  addCognitoUserToGroup,
} from "@/services/cognitoAdminQueries";

import { validatePhone } from "@/services/validatePhoneNumber";
// const initialProspectCriteria = [
//   {
//     birthYearRange: [1978, 1996],
//     retirementSavings: 0,
//   },
//   {
//     birthYearRange: [1926, 1977],
//     retirementSavings: 0,
//   },
// ];

const initialAdvisor = function () {
  return {
    companyName: null,
    companyUrl: null,
    companyLogo: null,
    address: {
      streetAddress1: null,
      streetAddress2: null,
      city: null,
      state: null,
      postalCode: null,
    },
    firstName: null,
    lastName: null,
    credentials: null,
    email: null,
    phone: null,
    avatar: null,
    prospectCriteria: null,
    showAdvisorCard: true,
    vanityNames: { items: [] },
    notificationEmails: [],
    disclosure: null,
    bypassCrm: null,
    scoreReportTemplateId: null,
    collectContactPostSurvey: null,
    customReportCtaUrl: null,
    organization: null,
    organizationId: null,
    acceptRegistrationTerms: null,
    promo_code: null,
    videoUrl: null,
    payment_method: null,
    stripe: null,
  };
};

export default {
  mixins: [parseCriteriaData, linkBuilderMixin],
  components: { AdvisorInfoCard, AdvisorTipTapEditor },
  data() {
    return {
      scoreReportTemplates: [],
      states: states,
      panels: [],
      panelsCount: 5, //number of expansion panels
      showOverlay: false,
      showSnackBar: false,
      snackBarColor: "info",
      snackBarText: "",
      dialog: false,
      dialogTitle: "",
      dialogText: "",
      showDialogActions: true,
      showDialogButtons: false,
      dialogStatus: "info",
      dialogActions: [
        {
          id: 1,
          text: "Back to advisor management",
          icon: "mdi-arrow-left",
          type: "back",
          action: "/admin/advisors",
        },
        {
          id: 2,
          text: "Edit advisor",
          icon: "mdi-pencil",
          type: "edit",
          action: "AdminAdvisorEdit",
        },
        {
          id: 3,
          text: "Copy advisor URL",
          icon: "mdi-content-copy",
          type: "copy",
          action: "",
        },
        {
          id: 4,
          text: "Add new advisor",
          icon: "mdi-account-plus",
          type: "reset",
          action: "/admin/advisor/add",
        },
      ],
      step: 1,
      mode: null,
      valid: false,
      advisor: null,
      notificationEmails: [],
      useNotificationEmails: false,
      checkingVanityName: false,
      vanityNameStatus: "info",
      vanityAllowed: false,
      newVanityName: "",
      vanityNamesToAdd: [],
      vanityNameFieldValid: false,
      yearBornOptions: [],
      retirementSavingsOptions: [
        "$0",
        "$1,000-$100,000",
        "$100,000-$250,000",
        "$250,000-$500,000",
        "$500,000-$750,000",
        "$750,000-$1,000,000",
        "$1,000,000-$1,500,000",
        "$1,500,000-$2,000,000",
        "$2,000,000-$2,500,000",
        "$2,500,000-$3,000,000",
        "$3,000,000-$4,000,000",
        "$4,000,000-$5,000,000",
        "$5,000,000-$10,000,000",
        "$10,000,000+",
      ],
      // retirementSavingsOptions: [
      //   {
      //     text: "$0-$100,000",
      //     value: 0,
      //   },
      //   {
      //     text: "$100,001-$250,000",
      //     value: 100001,
      //   },
      //   {
      //     text: "$250,001-$500,000",
      //     value: 250001,
      //   },
      //   {
      //     text: "$500,001-$750,000",
      //     value: 500001,
      //   },
      //   {
      //     text: "$750,001-$1,000,000",
      //     value: 750001,
      //   },
      //   {
      //     text: "$1,000,001-$2,000,000",
      //     value: 1000001,
      //   },
      //   {
      //     text: "$2,000,001-$3,000,000",
      //     value: 2000001,
      //   },
      //   {
      //     text: "$3,000,001-$4,000,000",
      //     value: 3000001,
      //   },
      //   {
      //     text: "$4,000,001-$5,000,000",
      //     value: 4000001,
      //   },
      //   {
      //     text: "$5,000,001+",
      //     value: 5000001,
      //   },
      // ],
      urlRules: [
        (v) =>
          !v ||
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
            v
          ) ||
          // /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
          //   v)
          "Must be a valid URL and begin with http:// or https://",
      ],
      youtubeUrlRules: [
        (v) =>
          !v ||
          /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm.test(
            v
          ) ||
          /https?:\/\/[^.]+\.(wistia\.com|wi\.st)\/(medias|embed)\/.*/.test(
            v
          ) ||
          "Must be a valid Youtube or Wistia URL",
      ],
      imageRules: [
        (v) => !v || v.size < 5000000 || "Image size should be less than 5 MB!",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      firstNameRules: [
        (v) => !!v || "First name is required",
        (v) =>
          (v && v.length >= 2) || "First name must be at least two letters",
      ],
      lastNameRules: [
        (v) => !!v || "Last name is required",
        (v) => (v && v.length >= 2) || "Last name must be at least two letters",
      ],
      zipCodeRules: [
        (v) =>
          !v ||
          /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) ||
          "Please enter a valid postal code.",
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Advisor" : "Edit Advisor";
    },
    checkVanityNamesLength() {
      return this.advisor.vanityNames.items.length;
    },
    initialProspectCriteria() {
      const years = this.generateYears();
      return [
        {
          birthYearRange: [years[0], years[years.length - 1]],
          retirementSavings: "$0",
        },
        {
          birthYearRange: [years[0], years[years.length - 1]],
          retirementSavings: "$0",
        },
      ];
    },
  },
  watch: {
    notificationEmails: {
      handler(val, prev) {
        if (val.length === prev.length) return;
        this.notificationEmails = val.map((v) => {
          if (typeof v === "string") {
            v = {
              text: v,
              color: "",
            };
          }
          return v;
        });
      },
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log("beforeRouteEnter");
  //   // react to route changes...
  //   next((vm) => {
  //     // access to component's instance using `vm` .
  //     // this is done because this navigation guard is called before the component is created.
  //     vm.checkRouteParam(vm, to.params.id);
  //   });
  // },
  // beforeRouteUpdate(to, from, next) {
  //   // react to route changes...
  //   console.log("beforeRouteUpdate");
  //   next((vm) => {
  //     // access to component's instance using `vm` .
  //     // this is done because this navigation guard is called before the component is created.
  //     vm.checkRouteParam(vm, to.params.id);
  //   });
  // },
  created() {
    const advisorId = this.$route.params.id;
    const routeName = this.$route.name;

    this.yearBornOptions = this.generateYears();
    this.advisor = new initialAdvisor();

    this.advisor.prospectCriteria = this.initialProspectCriteria;

    this.checkRouteParam(advisorId, routeName);
  },
  mounted() {
    this.getReportTemplates();
  },
  methods: {
    async checkRouteParam(id, routeName) {
      if (id && routeName === "AdminAdvisorEdit") {
        this.advisor = await this.fetchAdvisor(id);
        this.mode = "edit";
      } else {
        if (routeName !== "AdminAdvisorAdd") {
          this.$router.replace({ path: "/admin/advisor/add" });
        }
        this.mode = "new";
      }
    },
    handleCheckUrl(e) {
      let urlPattern = new RegExp(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim
        // /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
      );

      let string = e;

      if (!string) {
        return true;
      }

      if (urlPattern.test(string)) {
        string = string.replace("https://", "").replace("http://", "");
        string = `https://${string}`;

        this.advisor.companyUrl = string;
        return true;
      } else {
        return "Please enter a valid url.";
      }
    },
    generateYears() {
      const currentYear = new Date().getFullYear();
      const targetEndYear = currentYear - 26;
      let firstYear = currentYear - 80;
      let years = [];
      for (var i = firstYear; i <= targetEndYear; i++) {
        years.push(firstYear++);
      }
      return years;
    },
    async fetchAdvisor(id) {
      try {
        const response = await API.graphql(
          graphqlOperation(customGetAdvisor, { id: id })
        );
        const advisor = response.data.getAdvisor;

        if (advisor) {
          if (!advisor.address) {
            advisor.address = {
              streetAddress1: null,
              streetAddress2: null,
              city: null,
              state: null,
              postalCode: null,
            };
          }

          if (!advisor.notificationEmails) {
            advisor.notificationEmails = [];
          }

          if (
            advisor.notificationEmails &&
            advisor.notificationEmails.length > 0
          ) {
            this.useNotificationEmails = true;
            this.notificationEmails = advisor.notificationEmails;
            this.setNotificationEmails(advisor.notificationEmails);
          }
          console.log("THE ADVISOR IN CHECK", advisor);
          return this.parseCriteriaData(advisor);
        }
      } catch (error) {
        const message = `<p>There has been a problem attempting to look up the advisor.</p>
          <p><strong>${error.errors[0].message}</strong></p>
          <p>Please refresh and try again.</p>`;
        this.dialogTitle = "Error!";
        this.dialogStatus = "error";
        this.dialogText = message;
        this.dialog = true;
        this.showDialogActions = false;
        this.showDialogButtons = false;
        console.log("Error fetching advisor", error);
      }
    },
    // async handleCreateCognitoUser(advisorParams) {
    //   try {
    //     let apiName = "AdminQueries";
    //     let path = "/createUser";
    //     let init = {
    //       body: {
    //         username: advisorParams.email,
    //         email: advisorParams.email,
    //         // password:
    //       },
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `${(await Auth.currentSession())
    //           .getAccessToken()
    //           .getJwtToken()}`,
    //       },
    //     };
    //     const response = await API.post(apiName, path, init);

    //     console.log("in handle create new cognito user", response);
    //     return response;
    //   } catch (error) {
    //     const message = `<p>There has been a problem creating the advisor.</p>
    //       <p><strong>${error.response.data.message}</strong></p>
    //       <p>Please try again.</p>`;
    //     this.showOverlay = false;
    //     this.dialogTitle = "Error!";
    //     this.dialogStatus = "error";
    //     this.dialogText = message;
    //     this.dialog = true;
    //     this.showDialogActions = false;
    //     this.showDialogButtons = true;
    //     console.log(
    //       "error signing up new advisor cognito user: ",
    //       error.response.data.message
    //     );
    //   }
    // },
    // async handleAddCognitoUserToGroup(advisorParams) {
    //   try {
    //     let apiName = "AdminQueries";
    //     let path = "/addUserToGroup";
    //     let myInit = {
    //       body: {
    //         username: advisorParams.email,
    //         groupname: "Advisor",
    //       },
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `${(await Auth.currentSession())
    //           .getAccessToken()
    //           .getJwtToken()}`,
    //       },
    //     };
    //     return await API.post(apiName, path, myInit);
    //   } catch (error) {
    //     const message = `<p>There has been a problem creating the advisor.</p>
    //       <p><strong>${error.response.data.message}</strong></p>
    //       <p>Please try again.</p>`;
    //     this.showOverlay = false;
    //     this.dialogTitle = "Error!";
    //     this.dialogStatus = "error";
    //     this.dialogText = message;
    //     this.dialog = true;
    //     this.showDialogActions = false;
    //     this.showDialogButtons = true;
    //     console.log(
    //       "error adding new advisor cognito user to group: ",
    //       error.response.data.message
    //     );
    //   }
    // },
    setNotificationEmails(emails) {
      const emailsToObjects = [];
      emails.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: "",
          };
        }

        emailsToObjects.push(v);
      });

      this.notificationEmails = emailsToObjects;
    },
    async handleCreateAdvisor() {
      this.showOverlay = true;
      const advisorParams = Object.assign({}, this.advisor);

      if (advisorParams.phone) {
        const isValid = await this.handleValidatePhone(advisorParams.phone);

        if (!isValid) {
          return;
        }
      }

      try {
        const newCognitoAdvisor = await createCognitoUser(advisorParams);
        // console.log("newCognitoAdvisor", newCognitoAdvisor);
        // console.log("newCognitoAdvisor.message", newCognitoAdvisor.message);
        // console.log("newCognitoAdvisor.response", newCognitoAdvisor.response);

        // if newCognitoAdvisor.response then it isn't successful
        if (
          newCognitoAdvisor &&
          newCognitoAdvisor.response &&
          newCognitoAdvisor.response.status !== 200
        ) {
          throw new Error(newCognitoAdvisor.response.data.message);
        } else if (newCognitoAdvisor && newCognitoAdvisor.message) {
          console.log(
            "newCognitoAdvisor.response.status",
            newCognitoAdvisor.message
          );

          const newAdvisorInGroup = await addCognitoUserToGroup(
            advisorParams,
            "Advisor"
          );

          console.log("newAdvisorIn Group", newAdvisorInGroup);
        } else {
          throw new Error(newCognitoAdvisor.response.data.message);
        }
      } catch (error) {
        const message = `<p>There has been a problem creating the advisor.</p>
          <p><strong>${error}</strong></p>
          <p>Please try again.</p>`;
        this.showOverlay = false;
        this.dialogTitle = "Error!";
        this.dialogStatus = "error";
        this.dialogText = message;
        this.dialog = true;
        this.showDialogActions = false;
        this.showDialogButtons = true;
        console.log("Error signing up new advisor cognito user: ", error);
        return;
      }

      // Do not send Vanity Names object
      delete advisorParams.vanityNames;
      delete advisorParams.organization;
      delete advisorParams.organizationId;
      // Create vanity name
      // const fullName = advisorParams.name.split(" ");
      // const initials = fullName.shift().charAt(0) + fullName.pop();
      // const advisorVanityName = initials.toLowerCase();
      // const firstLetter = this.advisor.firstName.charAt(0).toLowerCase(); // get first letter of first name
      // const lastName = this.advisor.lastName.toLowerCase();
      // const advisorVanityName = firstLetter + lastName;
      const stringifyProspectCriteria = JSON.stringify(
        advisorParams.prospectCriteria
      );
      advisorParams.prospectCriteria = stringifyProspectCriteria;

      // Advisor notification email addresses
      if (this.useNotificationEmails && this.notificationEmails.length > 0) {
        await Promise.all(
          this.notificationEmails.map((e) => {
            advisorParams.notificationEmails.push(e.text);
          })
        );
      } else {
        advisorParams.notificationEmails = null;
      }

      // Upload Company Logo to S3
      try {
        if (advisorParams.companyLogo) {
          const companyLogoFile = advisorParams.companyLogo;
          const companyLogoS3Key = await Storage.put(
            companyLogoFile.name,
            companyLogoFile,
            {
              contentType: "image/*",
            }
          );
          advisorParams.companyLogo = {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: companyLogoS3Key.key,
          };
        }
      } catch (error) {
        console.log("Error uploading company logo file: ", error);
        const message = `<p>There has been a problem attempting to upload images.</p>
          <p><strong>${error.errors[0].message}</strong></p>
          <p>Please refresh and try again.</p>`;
        this.dialogTitle = "Error!";
        this.dialogStatus = "error";
        this.dialogText = message;
        this.dialog = true;
        this.showDialogButtons = false;
        this.showDialogActions = false;
      }
      // Upload Advisor Avatar to S3
      try {
        if (advisorParams.avatar) {
          const avatarLogoFile = advisorParams.avatar;
          const avatarS3Key = await Storage.put(
            avatarLogoFile.name,
            avatarLogoFile,
            {
              contentType: "image/*",
            }
          );
          advisorParams.avatar = {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: avatarS3Key.key,
          };
        }
      } catch (error) {
        console.log("Error uploading avatar file: ", error);
        const message = `<p>There has been a problem attempting to images.</p>
          <p><strong>${error.errors[0].message}</strong></p>
          <p>Please refresh and try again.</p>`;
        this.dialogTitle = "Error!";
        this.dialogStatus = "error";
        this.dialogText = message;
        this.dialog = true;
        this.showDialogButtons = false;
        this.showDialogActions = false;
      }

      try {
        console.log("About to createAdvisor. params are...", advisorParams);
        /// Add to DB
        const response = await API.graphql(
          graphqlOperation(customCreateAdvisor, { input: advisorParams })
        );

        // New advisor
        let createdAdvisor = response.data.createAdvisor;
        // Set vanity names on advisor
        await this.handleUpdateAdvisorVanityNames(createdAdvisor.id);
        // Parse criteria data on new advisor
        createdAdvisor = await this.parseCriteriaData(createdAdvisor);
        // Fetch updated advisor that now has vanity names obj
        this.advisor = await this.fetchAdvisor(createdAdvisor.id);

        this.dialogTitle = "Success!";
        this.dialogStatus = "success";
        this.dialogText = `Advisor <strong>${this.advisor.firstName} ${this.advisor.lastName}</strong> has been created!`;
        this.dialog = true;
        this.showDialogActions = true;
      } catch (error) {
        console.log("Error creating advisor ", error);
        const message = `<p>There has been a problem attempting to create advisor.</p>
          <p><strong>${error.errors[0].message}</strong></p>
          <p>Please refresh and try again.</p>`;
        this.dialogTitle = "Error!";
        this.dialogStatus = "error";
        this.dialogText = message;
        this.dialog = true;
        this.showDialogActions = false;
      }
      this.showOverlay = false;
    },
    vanityNameRules(v) {
      if (!v && this.mode === "new" && this.vanityNamesToAdd.length < 1) {
        this.vanityNameFieldValid = false;
        return "A vanity name is required.";
      } else if (v && v.length < 3) {
        this.vanityNameFieldValid = false;
        return "Vanity names must have at least 3 characters";
      } else if (v && v.length > 30) {
        this.vanityNameFieldValid = false;
        return "Vanity name exceeds maximum of 30 characters";
      } else if (v && /^([0-9a-z-])[a-z0-9-]*$/.test(v) === false) {
        this.vanityNameFieldValid = false;
        return "Vanity names may only contain lowercase letters, numbers, and hyphens";
      } else {
        this.vanityNameFieldValid = true;
        return true;
      }
    },
    async handleCheckVanityName(e) {
      if (e) {
        this.checkingVanityName = true;
      } else {
        this.vanityNameStatus = "info";
        return;
      }
      try {
        const nameExists = await this.vanityNamesToAdd.includes(
          this.newVanityName
        );
        const nameBlacklisted = await vanityNameBlacklist.includes(
          this.newVanityName
        );
        if (nameExists || nameBlacklisted) {
          this.vanityNameStatus = "error";
        } else {
          const response = await API.graphql(
            graphqlOperation(vanityInfoByVanityName, { vanityName: e })
          );
          if (response && response.data) {
            if (response.data.vanityInfoByVanityName.items.length > 0) {
              this.vanityNameStatus = "error";
            } else {
              // items length is 0, so can add new vanity name
              this.vanityNameStatus = "success";
            }
          }
        }
      } catch (error) {
        console.log("Check Vanity Name error: ", error);
        this.vanityNameStatus = "error";
      }
      this.checkingVanityName = false;
    },
    handleAddNewVanityName() {
      const vanityNameCount =
        this.checkVanityNamesLength + this.vanityNamesToAdd.length;
      if (vanityNameCount < 4) {
        this.vanityNamesToAdd.push(this.newVanityName);
      } else {
        alert("A maximum of 4 vanity names is permitted.");
      }
      this.newVanityName = "";
    },
    async handleRemoveVanityName(v) {
      // TODO remove vanity name when already added to DB
      this.vanityNamesToAdd.splice(this.vanityNamesToAdd.indexOf(v), 1);
    },
    async handleValidatePhone(num) {
      try {
        // validate phone number
        const response = await validatePhone(num);

        if (response.name === "Error") {
          throw new Error(response.message);
        } else {
          return true;
        }
      } catch (errorMsg) {
        console.log("validate phone error", errorMsg);

        this.dialogTitle = "Error!";
        this.dialogStatus = "error";
        this.dialogText = `
              <p><strong>${errorMsg}</strong></p>
              <p class="error--text"><strong>${num}</strong></p>
              <p>Please enter a valid US phone number for the advisor.</p>`;
        this.dialog = true;
        this.showDialogActions = false;
        this.showDialogButtons = true;
        this.showOverlay = false;
        return false;
      }
    },
    async handleUpdateAdvisor() {
      this.showOverlay = true;
      const advisorParams = Object.assign({}, this.advisor);
      if (advisorParams) {
        if (advisorParams.phone) {
          const isValid = await this.handleValidatePhone(advisorParams.phone);

          if (!isValid) {
            return;
          }
        }

        // Check if it is a file with name property
        // Upload image if so
        // Temporary - if not, don't upload anything b/c it is empty
        // or there is already an image in S3
        if (advisorParams.companyLogo && advisorParams.companyLogo.name) {
          const companyLogoFile = advisorParams.companyLogo;
          try {
            const companyLogoS3Key = await Storage.put(
              companyLogoFile.name,
              companyLogoFile,
              {
                contentType: "image/*",
              }
            );
            advisorParams.companyLogo = {
              bucket: awsExports.aws_user_files_s3_bucket,
              region: awsExports.aws_user_files_s3_bucket_region,
              key: companyLogoS3Key.key,
            };
          } catch (error) {
            console.log("Error uploading company logo file: ", error);
          }
        }
        if (advisorParams.avatar && advisorParams.avatar.name) {
          const avatarFile = advisorParams.avatar;
          try {
            const avatarFileS3Key = await Storage.put(
              avatarFile.name,
              avatarFile,
              {
                contentType: "image/*",
              }
            );
            advisorParams.avatar = {
              bucket: awsExports.aws_user_files_s3_bucket,
              region: awsExports.aws_user_files_s3_bucket_region,
              key: avatarFileS3Key.key,
            };
          } catch (error) {
            console.log("Error uploading company logo file: ", error);
          }
        }
        const stringifyProspectCriteria = JSON.stringify(
          advisorParams.prospectCriteria
        );
        advisorParams.prospectCriteria = stringifyProspectCriteria;

        //Advisor notification email addresses
        if (this.useNotificationEmails && this.notificationEmails.length > 0) {
          const notificationEmailsToARaw = [];

          await Promise.all(
            this.notificationEmails.map((e) => {
              notificationEmailsToARaw.push(e.text);
            })
          );
          advisorParams.notificationEmails = notificationEmailsToARaw;
          console.log(
            "advisorParams.notificationEmails ",
            advisorParams.notificationEmails
          );
        } else {
          advisorParams.notificationEmails = null;
        }

        // Do not send Prospects object
        delete advisorParams.prospects;
        // Do not send Vanity Names object
        delete advisorParams.vanityNames;

        delete advisorParams.createdAt;
        delete advisorParams.updatedAt;
        delete advisorParams.__typename;
        delete advisorParams.address.__typename;
        delete advisorParams.organization;

        if (!advisorParams.organization) {
          delete advisorParams.organization;
        }

        console.log("ADVISOR APRAMS TO SAVE", advisorParams);

        try {
          const response = await API.graphql(
            graphqlOperation(updateAdvisor, { input: advisorParams })
          );
          const updatedAdvisor = response.data.updateAdvisor;
          const parsedUpdatedAdvisor = this.parseCriteriaData(updatedAdvisor);

          Object.assign(this.advisor, parsedUpdatedAdvisor);

          if (this.vanityNamesToAdd.length > 0) {
            await this.handleUpdateAdvisorVanityNames(advisorParams.id);
          }

          this.dialogTitle = "Success!";
          this.dialogStatus = "success";
          this.dialogText = `Advisor <strong>${this.advisor.firstName} ${this.advisor.lastName}</strong> has been updated!`;
          this.dialog = true;
          this.showDialogActions = true;
        } catch (error) {
          console.log("Error updating Advisor...", error.errors[0].message);
          const message = `<p>There has been a problem attempting to update the advisor.</p>
          <p><strong>${error.errors[0].message}</strong></p>
          <p>Please refresh and try again.</p>`;
          this.dialogTitle = "Error!";
          this.dialogStatus = "error";
          this.dialogText = message;
          this.dialog = true;
          this.showDialogActions = false;
        }
      }
      this.showOverlay = false;
    },

    async handleUpdateAdvisorVanityNames(advisorId) {
      // Adds vanity names to the vanity names db table with the current advisor id for connection
      try {
        await Promise.all(
          this.vanityNamesToAdd.map(async (vn) => {
            await API.graphql(
              graphqlOperation(createAdvisorVanityName, {
                input: { advisorId: advisorId, vanityName: vn },
              })
            );
          })
        );
        this.vanityNamesToAdd = [];
      } catch (error) {
        console.log("Error creating vanity name...", error);

        this.handleSnackbar({
          isShowing: true,
          color: "error",
          text: `Error while attempting to create vanity name: ${error.errors[0].message}`,
        });
      }
    },
    handleSave() {
      switch (this.mode) {
        case "new":
          this.handleCreateAdvisor();
          break;
        case "edit":
          this.handleUpdateAdvisor();
          break;
      }
    },
    handleDialogAction(type, action) {
      const advisorId = this.advisor.id;
      switch (type) {
        case "back":
          this.$router.push(action);
          break;
        case "copy":
          this.handleCopyVanityLink(
            this.advisor.vanityNames.items[0].vanityName
          );
          break;
        case "edit":
          if (this.mode === "edit") {
            this.dialog = false;
          } else {
            this.$router.push({
              name: action,
              params: { id: advisorId },
              replace: true,
            });
          }

          break;
        case "reset":
          if (this.mode === "new") {
            this.$router.go();
          } else {
            this.$router.push(action);
          }
          break;
        default:
          break;
      }
    },
    handleCopyVanityLink(vanityName) {
      const path = process.env.VUE_APP_SCORE_LINK_PARAM;
      const vanityLink = this.copyLink(path, vanityName);

      if (vanityLink) {
        this.handleSnackbar({
          isShowing: true,
          color: "info",
          text: "Advisor's Vanity Link Copied!",
        });
      }
    },
    async handleCopyReportLink(id) {
      try {
        const path = process.env.VUE_APP_REPORT_LINK_PARAM;
        const url = this.copyLink(path, id);

        if (url) {
          // this.linkCopied = true;

          this.showSnackBar = true;
          this.snackBarText =
            "Prospect's Report Link Copied! Open in Incognito Browser.";
          this.snackBarColor = "info";
        }
        // setTimeout(() => {
        //   this.linkCopied = false;
        // }, 3000);
      } catch ($e) {
        // this.linkCopied = false;
      }
    },
    handleOpenReportLink(id) {
      const path = process.env.VUE_APP_SCORE_LINK_PARAM;
      const origin = window.location.origin;
      const url = `${origin}/${path}/${id}`;
      return url;
    },
    handleSnackbar({ isShowing, color, text }) {
      this.showSnackBar = isShowing;
      this.snackBarColor = color;
      this.snackBarText = text;
    },
    prospectSurveyCompleteCount(prospects) {
      const count = [];

      prospects.forEach((p) => {
        if (p.scoreSurveyComplete) {
          count.push(p.id);
        }
      });

      return count.length;
    },
    handleTogglePanels(dir) {
      if (dir === "close") {
        this.panels = [];
      } else {
        this.panels = [...Array(this.panelsCount).keys()].map((k, i) => i);
      }
    },
    handleRemoveEmail(item) {
      this.notificationEmails.splice(this.notificationEmails.indexOf(item), 1);
      this.notificationEmails = [...this.notificationEmails];
    },
    handleEmailRules(value) {
      const emailPattern = new RegExp(/.+@.+\..+/);
      let validState = true;
      const validityCheck = [];

      if (value instanceof Array && value.length == 0) {
        return "Required";
      }

      value.forEach((email) => {
        const isEmail = emailPattern.test(email.text);

        if (!isEmail) {
          email.color = "error";
          validityCheck.push("invalid");
        } else {
          email.color = "success";
          validityCheck.push("valid");
        }
      });

      if (validityCheck.includes("invalid")) {
        validState = "Only valid emails permitted!";
      } else {
        validState = true;
      }
      return validState;
    },
    async getReportTemplates() {
      // Get all report templates to set in select
      try {
        const response = await API.graphql(
          graphqlOperation(listReportTemplates)
        );
        const result = response.data.listReportTemplates;

        if (!result.items || result.items.length === 0) {
          throw new Error("No report templates could be found.");
        } else {
          this.scoreReportTemplates = result.items.filter((t) => {
            return t.version === 2;
          });
        }
      } catch (error) {
        this.showSnackBar = true;
        (this.snackBarText = "Error retrieving report templates: "), error;
        this.snackBarColor = "error";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-header {
  border: 1px solid lightgray;
  border-bottom: none;

  &--active {
    background-color: var(--v-primary-base);
    color: white;
  }
}

.v-expansion-panel-content {
  border: 1px solid var(--v-primary-base);
}
</style>
<style lang="scss">
.theme--light.v-expansion-panels .v-expansion-panel-header--active {
  .v-expansion-panel-header__icon .v-icon,
  .error--text {
    color: white !important;
  }
}

.v-application--is-ltr .rs-admin-edit .v-banner__wrapper {
  padding: 1rem;
}
</style>
