export const customCreateAdvisor = /* GraphQL */ `
  mutation CustomCreateAdvisor(
    $input: CreateAdvisorInput!
    $condition: ModelAdvisorConditionInput
  ) {
    createAdvisor(input: $input, condition: $condition) {
      id
      companyName
      companyUrl
      companyLogo {
        bucket
        region
        key
      }
      companyAddress
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
      }
      firstName
      lastName
      email
      credentials
      phone
      avatar {
        bucket
        region
        key
      }
      showAdvisorCard
      prospectCriteria
      notificationEmails
      disclosure
      bypassCrm
      customReportCtaUrl
      videoUrl
      scoreReportTemplateId
      collectContactPostSurvey
      acceptRegistrationTerms
      promo_code
      payment_method
      stripe
    }
  }
`;
