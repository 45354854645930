export const customGetAdvisor = /* GraphQL */ `
  query CustomGetAdvisor($id: ID!) {
    getAdvisor(id: $id) {
      id
      companyName
      companyUrl
      companyLogo {
        bucket
        region
        key
      }
      companyAddress
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
      }
      firstName
      lastName
      email
      credentials
      phone
      avatar {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      showAdvisorCard
      prospectCriteria
      prospects {
        items {
          id
          scoreSurveyComplete
        }
        nextToken
      }
      vanityNames {
        items {
          id
          createdAt
          vanityName
          advisorId
          updatedAt
        }
        nextToken
      }
      notificationEmails
      disclosure
      bypassCrm
      customReportCtaUrl
      videoUrl
      scoreReportTemplateId
      collectContactPostSurvey
      organizationId
      acceptRegistrationTerms
      payment_method
      stripe
    }
  }
`;
